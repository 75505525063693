const TutorialsData = [
  {
    href: 'tutorials/tutorial-forms',
    title: 'Build accessible forms with React',
  },
  {
    href: 'tutorials/tutorial-todo-list',
    title: 'Build an accessible todo list with React',
  },
];

export default TutorialsData;
